import { Camera, Euler, Group, Matrix4, Plane, Quaternion, Raycaster, TorusGeometry, Vector3 } from "three";
import { memberWithPrivateData } from "../../Utils";
import { TransformSpace } from "./BoxControls";
import { Gizmo } from "./Gizmo";
import { DEFAULT_X_COLORS, DEFAULT_Y_COLORS, DEFAULT_Z_COLORS, GizmoHandle, GizmoHandleColors } from "./GizmoHandle";

/** The radius in pixels of the handle */
const RADIUS_IN_PIXELS = 80;

/** The girth of the handle */
const GIRTH = 0.05;

/** The geometry of the handle */
const GEOMETRY = new TorusGeometry(1, GIRTH, 3, 64);

/** The gizmo to rotate a box */
export class BoxRotationGizmo extends Gizmo {
	xHandle: RotationHandle;
	yHandle: RotationHandle;
	zHandle: RotationHandle;

	handles: RotationHandle[] = [];

	#tempVec1 = new Vector3();

	/**
	 *
	 * @param element The HTML element on which the scene is rendered
	 * @param camera The camera used in the scene
	 * @param space The space on which the gizmo operates
	 */
	constructor(
		element: HTMLElement,
		camera: Camera,
		public space: TransformSpace,
	) {
		super(element, camera);

		// Create all handles geometries.
		// The matrixWorldAutoUpdate and matrixAutoUpdate are disabled
		// because the composition and decomposition of the matrices does not work
		// with not-uniform scales, so we are managing them manually
		this.xHandle = new RotationHandle(new Vector3(1, 0, 0), DEFAULT_X_COLORS);
		this.xHandle.matrixWorldAutoUpdate = false;
		this.xHandle.matrixAutoUpdate = false;

		this.yHandle = new RotationHandle(new Vector3(0, 1, 0), DEFAULT_Y_COLORS);
		this.yHandle.matrixWorldAutoUpdate = false;
		this.yHandle.matrixAutoUpdate = false;

		this.zHandle = new RotationHandle(new Vector3(0, 0, 1), DEFAULT_Z_COLORS);
		this.zHandle.matrixWorldAutoUpdate = false;
		this.zHandle.matrixAutoUpdate = false;

		this.add(this.xHandle);
		this.add(this.yHandle);
		this.add(this.zHandle);

		this.handles.push(this.xHandle, this.yHandle, this.zHandle);
	}

	/** @inheritdoc */
	override updateMatrixWorld(force?: boolean | undefined): void {
		super.updateMatrixWorld(false);

		for (const handle of this.handles) {
			this.computeHandleMatrices(handle, force);
		}
	}

	/**
	 * Show/hide the x axis handle
	 */
	set showX(show: boolean) {
		this.xHandle.visible = show;
	}

	/**
	 * Show/hide the y axis handle
	 */
	set showY(show: boolean) {
		this.yHandle.visible = show;
	}

	/**
	 * Show/hide the z axis handle
	 */
	set showZ(show: boolean) {
		this.zHandle.visible = show;
	}

	/**
	 * Compute the position, rotation and quaternion of the handle based on the current
	 * box configuration and the space the gizmo is working in
	 *
	 * @param handle The handle for which the new 3D configuration should be computed
	 * @param force Flag specyfing if this update was manually forced
	 */
	private computeHandleMatrices = memberWithPrivateData(() => {
		const TEMP_MATRIX = new Matrix4();
		const matrix = new Matrix4();

		const rotationAxis = new Vector3();
		const scale = new Vector3();
		const position = new Vector3();
		const quaternion = new Quaternion();

		const Z_AXIS = new Vector3(0, 0, 1);

		return (handle: RotationHandle, force?: boolean) => {
			// Compute the scaling factor for the AXIS_LENGTH_IN_PIXELS dimension
			let factor = this.computePixelsToMetersFactor(handle, RADIUS_IN_PIXELS);
			const worldScale = this.getWorldScale(this.#tempVec1);

			// Clamp "factor" so that the axis are always smaller than the box and remain inside.
			// The factor should be less than CLAMP_FACTOR * minScale
			const CLAMP_FACTOR = 0.4;
			const minScale = Math.min(worldScale.x, Math.min(worldScale.y, worldScale.z));
			if (factor / minScale > CLAMP_FACTOR) factor = minScale * CLAMP_FACTOR;

			// Compute the rotation axis of the handle: all the handles are toruses with Y as up direction,
			// so we need to compute the rotation needed to align them with X, Y and Z
			rotationAxis.set(handle.axis.x, handle.axis.y, handle.axis.z).cross(Z_AXIS).normalize();
			if (rotationAxis.length() > Number.EPSILON) {
				matrix.makeRotationAxis(rotationAxis, -Math.PI * 0.5);
			} else {
				matrix.identity();
			}

			switch (this.space) {
				case "local": {
					TEMP_MATRIX.copy(this.matrixWorld);

					// Take into account the world matrix and the local handle orientation
					// to compute the local scale of the handle
					TEMP_MATRIX.multiply(matrix);
					scale.setFromMatrixScale(TEMP_MATRIX);

					// Compute the local matrix of the handle, by, in order:
					// 1. scaling the Y-up cylinder based on factor
					// 2. rotating the handle based on the which axis it represents (X, Y, Z)
					scale.set(factor / scale.x, factor / scale.y, factor / scale.z);
					matrix.multiply(TEMP_MATRIX.compose(position.set(0, 0, 0), quaternion.identity(), scale));
					matrix.decompose(handle.position, handle.quaternion, handle.scale);

					break;
				}
				case "world": {
					// Compute the desired world matrix of the handle, by, in order:
					// 1. scaling the Y-up torus based on factor
					// 2. rotating the handle based on the which axis it represents (X, Y, Z)
					// 3. translating the handle at the box center
					TEMP_MATRIX.makeTranslation(this.getWorldPosition(position));
					TEMP_MATRIX.multiply(matrix);
					TEMP_MATRIX.multiply(matrix.makeScale(factor, factor, factor));

					// Compute the local matrix L by inverting the world matrix of the group and multiplying
					// by the desired world matrix
					matrix.copy(TEMP_MATRIX);
					matrix.premultiply(TEMP_MATRIX.copy(this.matrixWorld).invert());
					matrix.decompose(handle.position, handle.quaternion, handle.scale);
					break;
				}
			}

			// Manually update the matrix of the object and of its children
			handle.matrix.copy(matrix);
			handle.updateMatrixWorld(force);
		};
	});
}

/** A torus used to rotate an object */
export class RotationHandle extends GizmoHandle {
	/** Name to recognize the Object in the scene graph */
	name = "RotationHandle";

	/**
	 *
	 * @param axis The direction which the handle rotates around
	 * @param colors The colors of the handle
	 */
	constructor(
		public axis: Vector3,
		colors: GizmoHandleColors,
	) {
		super(GEOMETRY, new Vector3(), new Euler(), colors);
	}

	/**
	 * Compute the new position of the group after the user dragged the handle
	 *
	 * @param group The group manipulated by the gizmo
	 * @param startPoint The 3D world coordinates of the mouse at the start of the interaction. The function
	 * will replace its value with the current mouse coordinates
	 * @param raycaster The raycaster used during the interaction
	 * @param space Specify if the interaction should be local or global
	 */
	onMouseDrag = memberWithPrivateData(() => {
		const plane = new Plane();
		const endPoint = new Vector3();
		const cross = new Vector3();
		const dir1 = new Vector3();
		const dir2 = new Vector3();
		const quaternion = new Quaternion();
		const center = new Vector3();
		const normal = new Vector3();

		const matrix = new Matrix4();

		return (group: Group, startPoint: Vector3, raycaster: Raycaster, space: TransformSpace): void => {
			// Compute the actual rotation axis
			switch (space) {
				case "local": {
					// Take into account the group world orientation
					normal.copy(this.axis).applyMatrix4(matrix.extractRotation(group.matrixWorld)).normalize();
					break;
				}
				case "world": {
					// The rotation axis is the handle axis
					normal.copy(this.axis);
					break;
				}
			}

			// Compute the plane used for the interaction: is the plane with the normal equal to
			// the rotation axis and startPoint as a coplanar point
			plane.setFromNormalAndCoplanarPoint(normal, startPoint);
			raycaster.ray.intersectPlane(plane, endPoint);

			// Get the center of the handle in world coordinates
			group.getWorldPosition(center);

			// Get the two radii going from the center to start and end point
			dir1.subVectors(startPoint, center).normalize();
			dir2.subVectors(endPoint, center).normalize();

			// Compute the rotation axis (should be equal to normal) and the amount of rotation
			cross.crossVectors(dir1, dir2);

			// Project cross on normal to get the actual rotation angle
			const dotProduct = cross.dot(normal);

			// Update the startPoint for the next interaction
			startPoint.copy(endPoint);

			// Compute the final quaternion
			quaternion.setFromAxisAngle(normal, Math.asin(dotProduct));
			group.quaternion.premultiply(quaternion);
		};
	});
}
